<template>
  <div class="Attachments">
    <AttachmentFilters
      v-if="showFilters"
      :search.sync="filters.search"
      :type.sync="filters.type"
      :display-type.sync="displayType"
    />

    <transition-group
      v-if="files.length"
      :class="['AttachmentList--' + displayType]"
      class="columns is-mobile is-multiline AttachmentList"
      tag="div"
      name="list"
    >
      <div
        v-for="file in filteredFiles"
        :key="file.id"
        :class="columnClass"
        class="column AttachmentList__Item Collection-Item"
      >
        <attachment :attachment="file" />
      </div>

      <div
        v-if="!filteredFiles.length"
        key="no_files-message"
        class="Attachment__list-no-files"
      >
        {{ $t('components.files_picker.no_files_with_selected_filters') }}
      </div>
    </transition-group>
  </div>
</template>

<script>
import Attachment from './Attachment'
import AttachmentFilters from './AttachmentFilters'

export default {
  components: {
    Attachment,
    AttachmentFilters
  },
  props: {
    showFilters: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => ([])
    },
    columnClass: {
      type: String,
      default: 'is-3-mobile is-2-tablet is-2-desktop'
    }
  },
  data () {
    return {
      filters: {
        type: '',
        search: ''
      },
      displayType: 'grid'
    }
  },
  computed: {
    filteredFiles () {
      const search = this.filters.search || ''
      const type = this.filters.type
      const normalizedSearch = search.toLowerCase().trim()
      let files = this.files.filter(file => file.name.toString().toLowerCase().indexOf(normalizedSearch) !== -1)
      if (type) files = files.filter(file => file.type === type)
      return files
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Attachments {
  .AttachmentList {
    &--list {
      .AttachmentList__Item {
        flex: 1 0 auto !important;
        width: 100%;
      }

      .Attachment {
        display: flex;
        flex-flow: row wrap;

        &__thumbnail {
          margin-bottom: 0;
          margin-right: 1rem;
        }

        &__preview {
          width: 50px;
        }

        &__content {
          display: flex;
          flex-flow: row wrap;
          flex: 1 1 auto;
          align-items: center;
        }
      }
    }
  }
}
</style>
