<template>
  <div class="AttachmentFilters">
    <div class="columns is-aligned-middle">
      <div class="column is-narrow">
        <div class="control">
          <input
            v-model="searchProp"
            :placeholder="$t('components.attachments.filter_files_by_name')"
            class="input"
            type="text"
            name="filter"
          >
        </div>
      </div>
      <div class="column is-narrow">
        <div class="control">
          <el-select
            v-model="typeProp"
            :placeholder="$t('components.attachments.file_types')"
            clearable
            size="mini"
          >
            <el-option
              v-for="localFileType in fileTypes"
              :key="localFileType.value"
              :label="localFileType.label"
              :value="localFileType.value"
            />
          </el-select>
        </div>
      </div>
      <div class="column is-narrow m-l-a">
        <div class="AttachmentFilters__displayTypes">
          <div
            v-for="localFileType in displayTypes"
            :key="localFileType.name"
            :class="{'is-active': localFileType.name === displayTypeValue}"
            :title="$t('components.attachments.view_as', { type: localFileType.name })"
            class="AttachmentFilters__displayType"
            @click="displayTypeValue = localFileType.name"
          >
            <i :class="[`hf-${localFileType.icon}`]" class="hf" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    displayType: {
      type: String,
      default: 'grid'
    }
  },
  data () {
    return {
      fileTypes: [
        { value: 'doc', label: 'Documents' },
        { value: 'image', label: 'Images' },
        { value: 'audio', label: 'Audio' },
        { value: 'video', label: 'Video' }
      ],
      displayTypes: [{ name: 'list', icon: 'list' }, { name: 'grid', icon: 'grid' }]
    }
  },
  computed: {
    displayTypeValue: {
      get () {
        return this.displayType
      },
      set (value) {
        this.$emit('update:displayType', value)
      }
    },
    searchProp: {
      get () {
        return this.search
      },
      set (value) {
        this.$emit('update:search', value)
      }
    },
    typeProp: {
      get () {
        return this.type
      },
      set (value) {
        this.$emit('update:type', value)
      }
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.AttachmentFilters {
  margin-bottom: 1rem;
  &__displayTypes {
    display: flex;
    font-size: 1.5rem;
  }
  &__displayType {
    cursor: pointer;
    margin-left: .5rem;
    &.is-active {
      color: $primary;
    }
  }
}
</style>
