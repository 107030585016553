<template>
  <a
    :href="$getUrl(`download/${attachment.id}`, 'api')"
    class="Attachment"
    target="_blank"
  >
    <div class="Attachment__thumbnail">
      <div :style="style" class="Attachment__preview">
        <i
          v-if="!hasImage || !isImage"
          :class="[`hf-file-${icons[attachment.type]}-o`]"
          class="hf hf-3x"
        />
        <div class="Attachment__mask">
          <i class="hf hf-download hf-2x" />
        </div>
      </div>
    </div>
    <div class="Attachment__content">
      <div class="Attachment__title has-text-centered">
        {{ attachment.name }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      icons: {
        doc: 'pdf',
        image: 'image',
        audio: 'audio',
        video: 'video'
      }
    }
  },
  computed: {
    isImage () {
      return this.attachment.type === 'image'
    },
    hasImage () {
      return this.isImage && !!this.attachment.path
    },
    style () {
      const style = {}
      this.isImage && (style.backgroundImage = `url('${this.attachment.path}')`)
      return style
    }
  }
}
</script>

<style rel='stylehseed/scss' lang='scss'>
@import "~utils";

.Attachments .Attachment {
  display: block;
  &:hover {
    .Attachment__mask {
      opacity: 1;
      i {
        animation: fadeOutDown 1.8s ease-out infinite both;
      }
    }
  }
  &__thumbnail {
    margin-bottom: 0.5rem;
  }
  &__preview {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    transition: transform .1s linear;
    background: center/cover no-repeat;
    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__mask {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparentize($grey-dark, .6);
    color: $white-ter;
    opacity: 0;
    transition: .3s ease-in 0s;
    i {
      transition: .3s ease-in 0s;
      opacity: 0;
    }
  }
  &__title {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
</style>
